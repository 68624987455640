import React from 'react';
import "../../styles/GlobalPages.css";
import TarifaBloque from '../../components/Tarifas/TarifaBloque';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import Tabs from '../../components/Tabs/Tabs';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import AceptarContacto from '../../components/Alertas/AceptarContacto';


let operadores = [];
let tarifas = [];
let respuesta = [];
let tarifasImagen = [];
let colort;

class TarifasDetalleTranslated extends React.Component {

    state = {
        loading: false,
        error: false,
        titulo: '',
        imagen: false,
        alert: false,
        datos: '',
        agrupar: 1

    };


    componentDidMount() {
        if (this.props.location.state.id == 1) {
            this.setState({ titulo: "TELEFONÍA MÓVIL" });
        }
        else if (this.props.location.state.id == 2) {
            this.setState({ titulo: "TELEFONÍA FIJA" });
        }
        else if (this.props.location.state.id == 3) {
            this.setState({ titulo: "WMAX" });
        }
        else if (this.props.location.state.id == 4) {
            this.setState({ titulo: "FIBRA ÓPTICA" });
        }
        else if (this.props.location.state.id == 5) {
            this.setState({ titulo: "Fibra + Móvil" });
        }
        else if (this.props.location.state.id == 6) {
            this.setState({ titulo: "TV" });
        }
        tarifasImagen = [];
        tarifas = [];
        respuesta = [];
        operadores = [];
        const appConf = JSON.parse(localStorage.getItem('appconf'));
        if (appConf != '' && appConf != null) {
            this.setState({ imagen: appConf.imagen_tarifa, agrupar: appConf.agrupar_tarifas });

        }
        this.fetchData();
        colort = '#3aa7df';
        if (process.env.REACT_APP_APP_NAME != 'WifiGuay') {
            colort = process.env.REACT_APP_TARIFAS;
        } else if (this.props.location.state.id == 6) {
            colort = '#d00987';
        } else {
            colort = '#3aa7df';
        }
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null });
        try {

            if (this.props.location.state.id == 1) {
                //Recuperamos tarifas moviles
                await api.Clientes.tarifasMoviles().then(
                    ({ data }) => (respuesta = data)
                );
                //console.log(respuesta);
                await api.Clientes.tarifasRed().then(
                    ({ data }) => (operadores = data)
                );
            } else if (this.props.location.state.id == 2) {
                //Recuperamos tarifas fijas
                await api.Clientes.tarifasfijas().then(
                    ({ data }) => (respuesta = data)
                );
                //console.log(respuesta);
            } else if (this.props.location.state.id == 3) {
                //Recuperamos tarifas internet
                await api.Clientes.tarifasInternet().then(
                    ({ data }) => (respuesta = data)
                );
                //console.log(respuesta)
            } else if (this.props.location.state.id == 4) {
                //Recuperamos tarifas internet
                await api.Clientes.tarifasFibra().then(
                    ({ data }) => (respuesta = data)
                );
                // console.log(respuesta)
            } else if (this.props.location.state.id == 5) {
                //Recuperamos tarifas internet
                await api.Clientes.tarifasCompuestas().then(
                    ({ data }) => (respuesta = data)
                );
                //console.log(respuesta);
            } else if (this.props.location.state.id == 6) {
                //Recuperamos tarifas tv
                await api.Clientes.tarifasTv().then(
                    ({ data }) => (respuesta = data)
                );
                //console.log(respuesta);
            }

            if (Array.isArray(respuesta)) {
                if (this.props.location.state.id == 3) {
                    for (let i = 0; i < respuesta.length; i++) {
                        if (respuesta[i].nombre_comercial_app) {
                            respuesta[i].nombre_comercial_app = respuesta[i].nombre_comercial_app.substr(0, 25);
                        }
                        if (respuesta[i].total_iva) {
                            respuesta[i].total_iva = parseFloat(respuesta[i].total_iva).toFixed(2);
                        }
                        if (respuesta[i].id_servicio_fibra == null) {
                            tarifas.push(respuesta[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < respuesta.length; i++) {
                        if (respuesta[i].nombre_comercial_app) {
                            respuesta[i].nombre_comercial_app = respuesta[i].nombre_comercial_app.substr(0, 25);
                        }
                        if (respuesta[i].total_iva) {
                            respuesta[i].total_iva = parseFloat(respuesta[i].total_iva).toFixed(2);
                        }
                        tarifas.push(respuesta[i]);
                    }
                }

                if (this.state.imagen == 1) {
                    for (let i = 0; i < tarifas.length; i++) {
                        if (tarifas[i].imagen_tarifa != '' && tarifas[i].imagen_tarifa != null) {
                            tarifasImagen.push(tarifas[i]);
                        }

                    }
                }

            } else if (respuesta == "sin datos" || respuesta == 'No se han encontrado datos para el modelo <b>tarifas_app</b>' || respuesta == 'No se han encontrado datos para el modelo <b>tarifas_compuestas</b>') {
                tarifas = [];
            } else {
                this.setState({ loading: false, error: true });
            }
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };

    contacto(tarifa) {
        this.setState({ alert: true, datos: tarifa });
    }




    render() {
        const { t } = this.props;

        if (this.state.loading === true && !this.state.datos) {
            return <PageLoading />;
        }

        if (tarifas.length == 0 || (this.state.imagen == 1 && tarifasImagen.length == 0)) {

            return (
                <div className="container h-100 ">
                    <div className="nav-navegacion">
                        <NavbarVariable datos={'/Tarifas'} />
                    </div>
                    <div className="titulo-pagina">
                        <span className="">{this.state.titulo}</span>
                    </div>
                    <div>
                        <span>{t('Tarifas.nohay',)}</span>
                    </div>
                </div>
            )

        }
        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.titulo === "TELEFONÍA MÓVIL") {
            if (this.state.imagen == 1) {
                return (
                    <div className="container h-100 ">
                        <div className="nav-navegacion">
                            <NavbarVariable datos={'/Tarifas'} />
                        </div>
                        <div className="titulo-pagina">
                            <span className="">{this.state.titulo}</span>
                        </div>
                        <div className="operadores-text">
                            {process.env.REACT_APP_APP_NAME == 'yutmovil' ? '' : t('Tarifas.tipo',)}
                        </div>
                        <div className="operadores">
                            <Tabs>
                                {operadores.map(operador => {
                                    return (
                                        <div label={operador.nombre} key={operador.id}>
                                            <div className="tarifasImagen">
                                                {tarifasImagen.map(tarifa => {
                                                    if (tarifa.id_red == operador.id) {
                                                        return (
                                                            <ul key={tarifa.id} onClick={() => this.contacto()}>
                                                                <img className="imagenTa" src={tarifa.imagen_tarifa} alt="Logotipo" />
                                                            </ul>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Tabs>
                        </div>
                        {this.state.alert && <AceptarContacto dato={this.props} />}
                        {this.state.loading && <MiniLoader />}
                        <PiePagina />
                    </div>
                )
            } else if (this.state.agrupar == 1) {
                return (
                    <div className="container h-100 ">
                        <div className="nav-navegacion">
                            <NavbarVariable datos={'/Tarifas'} />
                        </div>
                        <div className="titulo-pagina">
                            <span className="">{this.state.titulo}</span>
                        </div>
                        <div className="operadores-text">
                            {process.env.REACT_APP_APP_NAME == 'yutmovil' ? '' : t('Tarifas.tipo',)}
                        </div>
                        <div className="operadores">
                            <Tabs>
                                {operadores.map(operador => {
                                    return (
                                        <div label={operador.nombre} key={operador.id}>
                                            <div className="tarifas">
                                                {tarifas.map(tarifa => {
                                                    if (tarifa.id_red == operador.id) {
                                                        return (
                                                            <ul key={tarifa.id}>
                                                                <TarifaBloque
                                                                    id={tarifa.id}
                                                                    nombre={tarifa.nombre_comercial_app}
                                                                    descripcion={tarifa.descripcion_web}
                                                                    precio={tarifa.total_iva}
                                                                    ampliacion={tarifa.descripcion_web_ampliacion}
                                                                    color={operador.color_operador} />
                                                            </ul>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Tabs>
                        </div>
                        {this.state.loading && <MiniLoader />}
                        <PiePagina />
                    </div>
                )


            } else {
                return (
                    <div className="container h-100 ">
                        <div className="nav-navegacion">
                            <NavbarVariable datos={'/Tarifas'} />
                        </div>
                        <div className="titulo-pagina">
                            <span className="">{this.state.titulo}</span>
                        </div>
                        <div className="operadores">
                            <div className="tarifas">
                                {tarifas.map(tarifa => {
                                    return (
                                        <ul key={tarifa.id}>
                                            <TarifaBloque
                                                id={tarifa.id}
                                                nombre={tarifa.nombre_comercial_app}
                                                descripcion={tarifa.descripcion_web}
                                                precio={tarifa.total_iva}
                                                ampliacion={tarifa.descripcion_web_ampliacion}
                                                color={colort} />
                                        </ul>
                                    );

                                })}
                            </div>
                        </div>
                        {this.state.loading && <MiniLoader />}
                        <PiePagina />
                    </div>
                )

            }

        }

        if (this.state.imagen == 1 && process.env.REACT_APP_APP_NAME != 'DEMO') {
            return (
                <div className="container h-100 ">
                    <div className="nav-navegacion">
                        <NavbarVariable datos={'/Tarifas'} />
                    </div>
                    <div className="titulo-pagina">
                        <span className="">{this.state.titulo}</span>
                    </div>
                    <div>
                        <div className="tarifas">
                            {tarifasImagen.map(tarifa => {
                                return (
                                    <div onClick={() => this.contacto(tarifa)}>
                                        <img className="" src={tarifa.imagen_tarifa} alt="Logotipo" />
                                    </div>
                                );

                            })}
                        </div>
                    </div>
                    {this.state.alert && <AceptarContacto dato={this.state.datos} />}
                    {this.state.loading && <MiniLoader />}
                    <PiePagina />
                </div>
            )
        }
        return (

            <div className="container h-100 ">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Tarifas'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{this.state.titulo}</span>
                </div>
                <div>
                    <div className="tarifas">
                        {tarifas.map(tarifa => {
                            return (
                                <ul key={tarifa.id}>
                                    <TarifaBloque
                                        id={tarifa.id}
                                        nombre={tarifa.nombre_comercial_app}
                                        descripcion={tarifa.descripcion_web}
                                        precio={tarifa.total_iva}
                                        ampliacion={tarifa.descripcion_web_ampliacion}
                                        color={colort} />
                                </ul>
                            );
                        })}
                    </div>

                </div>
                {this.state.loading && <MiniLoader />}
                <PiePagina />
            </div>
        )
    }
}
const TarifasDetalle = withTranslation('common')(TarifasDetalleTranslated)
export default TarifasDetalle;